
















































































  import { Validator } from '@/constant/Mixins';
  import { UserModel, UserType } from '@/store/auth/types';
  import { AxiosResponse } from 'axios';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action, State } from 'vuex-class';

  @Component({
    name: 'Register',
    components: {},
    mixins: [],
  })
  export default class Register extends Mixins(Validator) {
    @State('user', { namespace: 'auth' }) form!: UserModel;
    @Action('register', { namespace: 'auth' }) register!: (params?: UserType) => Promise<AxiosResponse>;

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }

    /**
     * OnSubmit
     */
    public OnSubmit() {
      this.register().then((response: AxiosResponse) => this.$router.push({ name: 'dashboard' }));
    }
  }
